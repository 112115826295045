import { FC } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { CouponMetadata } from 'src/core/Reservation/domain/Reservation.model'
import {
  head,
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Button, Modal } from 'src/ui/components'
import {
  CouponsFormValues,
  CouponFormType,
  useCouponsForm,
} from 'src/ui/hooks/useCouponsForm'
import { useTrans } from 'src/ui/hooks/useTrans'

import styles from './EditCouponsModal.module.scss'
import { createModal } from 'src/ui/hooks/useModal'
import { CouponsFormContent } from 'src/ui/views/_layouts/AvailableRooms/CouponsFormContent'
import { CouponValidationResult } from 'src/core/Hotel/domain/CouponValidation.model'
import { LevelCoupon } from './LevelCoupon'
import { User } from 'src/core/User/domain/User'

export interface EditCouponsModalProps {
  couponsMetadata: CouponMetadata[] | undefined
  onEditCoupon: (formCoupon: CouponsFormValues) => Promise<void>
  onClose: () => void
  onValidateCoupon: (coupon: string) => Promise<CouponValidationResult>
  hasToShowLevelCoupon?: boolean
  user?: User
}

export const EditCouponsModal = createModal(
  ({
    couponsMetadata,
    onEditCoupon,
    onClose,
    onValidateCoupon,
    hasToShowLevelCoupon = false,
    user,
  }: EditCouponsModalProps) => {
    const couponsWithoutLevelCoupon = couponsMetadata
      ?.map(couponMetadata => couponMetadata.coupon)
      .filter(coupon => coupon.type !== 'myBarcelo')

    const initialCoupon = head(couponsWithoutLevelCoupon)
    const { methods, areValuesSameAsOld, getCouponWithEmptyOption } =
      useCouponsForm(
        isDefined(couponsWithoutLevelCoupon) ? initialCoupon : undefined,
      )

    const handleEditCouponsSubmit = async (formData: CouponsFormValues) => {
      const formCoupon = getCouponWithEmptyOption(formData)

      if (
        areValuesSameAsOld() ||
        (formCoupon.type === CouponFormType.None && isUndefined(initialCoupon))
      ) {
        onClose()
        return
      }
      await onEditCoupon(formCoupon)
      onClose()
    }
    const showLevelCoupon =
      hasToShowLevelCoupon &&
      isDefined(user) &&
      isDefined(user.discount) &&
      methods.getValues('type') !== CouponFormType.GroupCode

    return (
      <Modal
        size="m"
        mobileStyle="fullscreen"
        type="transactional"
        data-testid="edit-coupons-modal"
        withButtonsFooter
        onClose={onClose}
        data-quantum-modal="EditCouponsModal"
      >
        {showLevelCoupon && <LevelCoupon user={user} />}

        <Form
          handleEditCouponsSubmit={handleEditCouponsSubmit}
          methods={methods}
          onValidateCoupon={onValidateCoupon}
        />
      </Modal>
    )
  },
)

interface FormProps {
  handleEditCouponsSubmit: (formData: CouponsFormValues) => void
  methods: UseFormReturn<CouponsFormValues>
  onValidateCoupon: (coupon: string) => Promise<CouponValidationResult>
}

const Form: FC<FormProps> = ({
  handleEditCouponsSubmit,
  methods,
  onValidateCoupon,
}) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <section>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleEditCouponsSubmit)}>
          <CouponsFormContent
            methods={methods}
            onValidateCoupon={onValidateCoupon}
            revalidate={true}
            keyForValidPromotionalCodeMessage="edit-coupons_input_validation_valid"
            keyForInvalidValidPromotionalCodeMessage="edit-coupons_input_validation_invalid"
          />
          <div className={styles.submitButtonWrapper}>
            <Button
              size={{
                mobile: 'fullWidth',
                laptop: 'large',
              }}
            >
              {trans('new-reservation:coupons-form_submit')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </section>
  )
}
